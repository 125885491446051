const closeModal = (modalElem) => {
  const modal = document.querySelector('[data-modal-visible="true"]')
  if (!modal) return
  modal.classList.add('translate-y-full', 'opacity-0')
  setTimeout(() => {
    modal.remove()
    document.body.classList.toggle('overflow-hidden')
  }, 300);
}
const toggleModal = (imgSrcMedium, imgSrcLarge, alt = '') => {
  
  const modal = document.createElement('div')
  modal.classList.add('absolute', 'left-0', 'right-0', 'h-dvh', 'bg-black', 'z-50', 'transition', 'opacity-0', 'translate-y-full', 'duration-300')
  modal.setAttribute('data-modal-visible', 'true')
  // get scrollY
  const scrollY = window.scrollY
  modal.style.top = `${scrollY}px`
  document.body.appendChild(modal)
  document.body.classList.toggle('overflow-hidden')

  // animate the modal
  setTimeout(() => {
    modal.classList.remove('translate-y-full', 'opacity-0')
  }, 100);

  // create the content
  const container = document.createElement('div')
  container.classList.add('container', 'h-full', 'place-content-center', 'lg:px-16')
  const buttonWrapper = document.createElement('div')
  buttonWrapper.classList.add('flex', 'justify-end', 'mb-3')
  const button = document.createElement('button')
  button.textContent = 'x'
  button.onclick = () => closeModal()

  const img = document.createElement('img')
  img.classList.add('object-cover', 'object-center', 'w-full', 'h-full')
  img.src = imgSrcMedium
  img.alt = alt

  const source = document.createElement('source')
  source.srcset = imgSrcLarge
  source.media = '(min-width: 768px)'

  const picture = document.createElement('picture')
  const figure = document.createElement('figure')
  figure.classList.add('aspect-video', 'overflow-hidden')

  picture.appendChild(source)
  picture.appendChild(img)
  figure.appendChild(picture)
  container.appendChild(buttonWrapper)
  buttonWrapper.appendChild(button)
  container.appendChild(figure)

  modal.appendChild(container)
}

window.toggleModal = toggleModal;
window.closeModal = closeModal